@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari, and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge, and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* For Firefox */
/* These styles will not work on WebKit-based browsers. */
/* Make sure to include these styles after the WebKit-based styles to avoid conflicts. */
/* * {
  scrollbar-color: #8c8c8c #f1f5f9;
  scrollbar-width: thin;
} */

*::-webkit-scrollbar {
    width: 8px;
    height: 12px;
    /* Width of the scrollbar */
}

*::-webkit-scrollbar-thumb {
    background-color: #cecece;
    /* Color of the thumb (the draggable part of the scrollbar) */
    border-radius: 3px;
    /* Rounded corners for the thumb */
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #6b7280;
    /* Color of the thumb on hover */
}

*::-webkit-scrollbar-track {
    background-color: #f1f5f9;
    /* Color of the track (the area behind the thumb) */
    border-radius: 3px;
    /* Rounded corners for the track */
}

body {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f9fa;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr.horizontal.dark {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

hr.horizontal {
    background-color: transparent;
}

/* Gradient Text */
.gradient-text-rsf {
    margin: 0;
    font-size: 1.875rem;
    line-height: 1.375;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /* color: #344767; */
    font-weight: 500;
    letter-spacing: 0em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #e63a27;
    font-weight: 700;
    /* background-image: linear-gradient(310deg, #e63a27, #fc853b); */
    /* background-image: linear-gradient(310deg, #2152ff, #21d4fd); */
    display: inline-block;
    /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
    position: relative;
    z-index: 1;
}

.custom-grid-calender-view-popup {
    display: grid;
    grid-template-columns: 1fr 0.5fr 1.5fr;
}

.custom-input-rsf {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: grid !important;
    place-items: center !important;
    width: 100% !important;
    height: auto !important;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.4 !important;
    color: #495057 !important;
    background-color: #ffffff !important;
    -webkit-background-clip: padding-box !important;
    background-clip: padding-box !important;
    border: 0.0625rem solid #d2d6da;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    border-radius: 0.5rem;
    -webkit-transition: box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important;
    transition: box-shadow 150ms ease, border-color 150ms ease, padding 150ms ease !important;
    background-color: #ffffff;
    pointer-events: auto;
}

.custom-input-rsf:focus {
    border-color: #e63a27;
    /* box-shadow: 0rem 0rem 0rem 0.125rem rgba(129, 227, 249, 1); */
    box-shadow: 0rem 0rem 0rem 0.125rem #e63a2781;
    outline: 0;
}

.custom-input-rsf-2 {
    background: #fdfefe;
    border: 2px solid #dce1e4;
    border-color: #dce1e4;
    box-shadow: none;
    color: #3c3e41;
    height: 45px;
    padding: 0 15px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 28px;
    outline: none;
    border-radius: 6px;
}

.custom-input-rsf-2:focus {
    border-color: #e63a27;
}

.custom-label-rsf {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.03333em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #344767;
    font-weight: 700;
}

.custom-subtitle-rsf {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.01071em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #67748e;
    font-weight: 400;
}

.custom-rsf-btn {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    vertical-align: middle;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    letter-spacing: 0.02857em;
    min-width: 64px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 0.5rem;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    background-size: 150% !important;
    background-position-x: 25% !important;
    transition: all 150ms ease-in;
    min-height: 2.5rem;
    box-shadow: 0rem 0.25rem 0.4375rem -0.0625rem rgba(0, 0, 0, 0.11), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.07);
    padding: 0.75rem 1.5rem;
    /* background: linear-gradient(310deg, #2152ff, #21d4fd); */
    color: #ffffff;
}

.button {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    vertical-align: middle;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    letter-spacing: 0.02857em;
    min-width: 64px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 0.5rem;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    background-size: 150% !important;
    background-position-x: 25% !important;
    min-height: 2.5rem;
    box-shadow: 0rem 0.25rem 0.4375rem -0.0625rem rgba(0, 0, 0, 0.11), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.07);
    padding: 0.75rem 1.5rem;
    background-color: #e63a27;
    color: #ffffff;
    transition: all 0.3s ease;
}

.button:hover {
    background: #c72412;
}

.bg-1 {
    background: linear-gradient(310deg, #e63a27, #f47424);
}

.bg-2 {
    background: linear-gradient(310deg, #e63a27, #fc853b);
}

.bg-3 {
    background: linear-gradient(310deg, #e63a27, #f47424);
}

.text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 1;
}

.color-rsf {
    color: #e63a27;
}

.custom-login-page-img-box {
    flex-basis: 41.666667%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 41.666667%;
    box-sizing: border-box;
    margin: 0;
    flex-direction: row;
}

.custom-login-page-img-inner-box {
    right: -16rem;
    height: 100%;
    position: relative;
    margin-right: -128px;
    opacity: 1;
    background: transparent;
    color: #344767;
    border-radius: none;
    transform: skewX(-10deg);
    overflow: hidden;
    border-bottom-left-radius: 0.75rem;
}

.custom-login-page-img {
    margin-left: -64px;
    height: 100%;
    opacity: 1;
    background: transparent;
    color: #344767;
    border-radius: none;
    background-image: url(https://readysteadyfinance.co.uk/wp-content/uploads/2023/10/login.webp);
    background-size: cover;
    transform: skewX(10deg);
}

.sidenav-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 0.5rem;
    margin-bottom: 10px;
    gap: 0.5rem;
}

.active {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 0.5rem;
    margin-bottom: 10px;
    gap: 0.5rem;
    background-color: hsl(var(--b2) / var(--tw-bg-opacity));
    /* background-color: var(--bg-base-200); */
    /* background-color: #15191e; */
    /* box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05); */
}

.sidenav-btn:hover {
    /* background-color: #15191e; */
    /* box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05); */
}

/* Drag and drop Start */
.ocard {
    /* box-shadow: 0 0 5px #ffdfdf; */
    border-radius: 5px;
    overflow: hidden;
}

.ocard .otop {
    text-align: center;
}

.ocard p {
    font-weight: bold;
    color: #0086fe;
}

.ocard button {
    outline: 0;
    border: 0;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    padding: 8px 13px;
    width: 100%;
    background-color: #0086fe;
}

.ocard .drag-area {
    height: 100px;
    border-radius: 5px;
    border: 2px dashed #0086fe;
    color: #0086fe;
    background: #f4f3f9;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    margin-top: 10px;
}

.ocard .drag-area .visible {
    font-size: 18px;
}

.ocard .oselect {
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
}

.ocard .oselect:hover {
    opacity: 0.6;
}

.ocard .ocontainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
}

.ocard .ocontainer .pdf {
    width: 100%;
    background-color: #f4f3f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 8px;
}

.ocard .ocontainer .pdf img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.ocard .ocontainer .pdf span {
    /* position: absolute;
  top: -2px;
  right: 9px; */
    font-size: 20px;
    cursor: pointer;
}

.ocard input,
.ocard .drag-area .on-drop,
.ocard .drag-area.dragover .visible {
    display: none;
}

/* Drag and drop End */

.step {
    font-size: 13px;
}

.step-primary::after,
.step-primary::before {
    background-color: #e63a27 !important;
    /* Use the primary color here */
    color: #fff !important;
    /* Text color */
    /* Any additional styles you want for the primary step */
}

/* Arrow Box */
.arrow-box {
    border-radius: 4px;
    /* padding: 20px; */
    text-align: center;
    /* width: 200px; */
    position: relative;
}

.arrow-box::after,
.arrow-box::before {
    border: solid transparent;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
}

.arrow--1::after {
    border-width: 10px;
}

.arrow--1::before {
    border-width: 12px;
}

.arrow--1::after {
    /* border-left-color: #007de3; */
    top: 50%;
    margin-top: -10px;
    left: 100%;
}

.arrow--1::before {
    /* border-left-color: #007de3; */
    top: 50%;
    margin-top: -12px;
    left: 100%;
}

/* KanbanStatusColumn.css */

.status-initial-review {
    background-color: #387adf;
    /* Darker Blue */
}
.status-renewals {
    background-color: #ff9d00;
    /* Darker Blue */
}
.status-invoice-finance {
    background-color: #462c9a;
    /* Darker Blue */
}
.status-busness-loan {
    background-color: #462c9a;
    /* Darker Blue */
}
.status-mca {
    background-color: #462c9a;
    /* Darker Blue */
}
.status-6-month {
    background-color: #52a0fe;
    /* Darker Blue */
}
.status-open-bank {
    background-color: #52a0fe;
    /* Darker Blue */
}

.status-additional-documents {
    background-color: #5bbcff;
    /* Darker Orange */
}

.status-golden-data {
    background-color: #ffbf00;
    /* Darker Green */
    /* background-color: #FFC94A ;  */
}

.status-pre-underwriting {
    background-color: #6420aa;
    /* Darker Purple */
}

.status-the-great-takeover {
    background-color: #02ccbb;
    /* Darker Purple */
}

.status-pre-offer {
    background-color: #0c359e;
    /* Darker Purple */
}

.status-underwriting {
    background-color: #6420aa;
    /* Darker Red */
}

.status-extra-docs-ob {
    background-color: #5bbcff;
    /* Darker Orange */
}

.status-awaiting-offer {
    background-color: #6420aa;
    /* Darker Turquoise */
}

.status-offer {
    background-color: #ff9248;
    /* Darker Green */
}

.status-contract-sent {
    background-color: #ff6700;
    /* Darker Sky Blue */
}

.status-awaiting-funding {
    background-color: #7aba78;
    /* Darker Blue */
}

.status-funded-this-month {
    background-color: #0a6847;
    /* Darker Emerald */
}

.status-funded {
    background-color: #0a6847;
    /* Darker Midnight Blue */
}

.last-chance {
    background-color: #230a68;
    /* Darker Midnight Blue */
}

.status-qualified {
    background-color: #212c1e;
    /* Darker Turquoise */
}

.status-non-qualified {
    background-color: #3d3d3d;
    /* Darker Turquoise */
}

.status-cancelled {
    background-color: #e72929;
    /* Darker Red */
}

.status-cancelled-for-ever {
    background-color: #000000;
    /* Darker Turquoise */
}

.animatedButton {
    text-align: center;
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: sans-serif;
    box-sizing: border-box;
    background: linear-gradient(90deg, #e63a27, #ff6b35, #ffcd38, #e63a27);
    background-size: 400%;
    border-radius: 10px;
    z-index: 1;
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

.animatedButton:hover {
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}

@keyframes animate {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 400%;
    }
}

/* .animatedButton:before {
  animate: 0.5s;
  transition: 0.5s;
  content: '';
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: 0.5s;

} */

.animatedButton:hover:before {
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
    animate: 0.5s;
    transition: 0.5s;
}
